.button {
  appearance: none;
  outline: 0;
  background-color: #75d7a5;
  border: 0;
  padding: 20px 25px;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 15px; 
  width: 45%;
  opacity: 0.5;
  letter-spacing: 5px;


}
.button:hover{
opacity: 1;
}
