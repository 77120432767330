.inputStyle {
  background-color: #29363f;
  border-radius: 3px;
  font-family: "Source Sans Pro", sans-serif;
  padding: 20px 25px;
  margin: 0 auto 40px auto;
  display: block;
  font-size: 20px;
  color: white;
  font-weight: 300;
  text-align: start;
  border-radius: 5px;
  width: 40%;
  letter-spacing: 5px;
}
