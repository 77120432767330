@font-face {
  font-family: "Gloria";
  src: local("Gloria"),
    url(../public/fonts/Gloria_Hallelujah/GloriaHallelujah-Regular.ttf)
      format("truetype");
}
@font-face {
  font-family: "Hind";
  src: local("Hind"),
    url(../public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf)
      format("truetype");
  font-weight: 500;
}
